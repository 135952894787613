@import "@/styles/main.scss";
.width {
    width: calc(100% - 2 * 7.14%);
    margin: 0 auto;
    // position: absolute;
    left: 7.14%;
    top: 80%;
    transform: translateY(-50%);
}

.hero-text {
    letter-spacing: -0.08em;
    //font-size: 298px;
    font-size: 12.7vw;
    container-name: hero;
    color: #ffffff;
}

.wrapper {
    // pointer-events: none;
    position: relative;

.intro-text {
    position: absolute;
    left: var(--column-small);
    top: 9%;
    font-size: 70px;
    color: #fff;
    letter-spacing: -4.2px;
    line-height: 1;
    font-family: var(--font-display);
    max-width: var(--column-large);
}

    canvas {
        pointer-events: all;
        height: 500px;
        width: 100%;
    }
}

.body-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 100vh;
    bottom: 0;
    background: linear-gradient(187deg, rgba(25, 25, 25, 1) 8.07%, rgba(72, 191, 227, 1) 36.49%, rgba(89, 120, 211, 1) 71%, rgba(105, 48, 195, 1) 105.51%);
    &:before {
        content: '';
        background: linear-gradient(180deg, rgba(25, 25, 25, 1) 0%, rgba(25, 25, 25, 0.00) 100%);        
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 400px;
    }
}