@import "@/styles/main.scss";




.home-hero {
    // pointer-events: none;
    position: relative;
    z-index: 124;
    
    .width {
        width: calc(100% - 2 * 7.14%);
        margin: 0 auto;
        // position: absolute;
        left: 7.14%;
        top: 80%;
        transform: translateY(-50%);
        @include size-under(tablet) {
            position: static;
            top: 100%;
            margin-top: 20px;
            transform: translateY(0%);
        }
    }

    .hero-text {
        letter-spacing: -0.08em;
        font-size: 12.7vw;
        container-name: hero;
        color: #ffffff;
        line-height: 0.7;
        font-family: var(--font-display);
        font-weight: 700;
        @include size-under(tablet) {
            font-size: 12.5vw;
        }
        @include size-under(phone) {
            font-size: 5.2rem;
            line-height: 0.9;
        }
    }

    .intro-text {
        position: absolute;
        left: var(--column-small);
        top: 40%;
        transform: translateY(-50%);
        font-size: 70px;
        color: #fff;
        letter-spacing: -4.2px;
        line-height: 1;
        font-family: var(--font-display);
        max-width: var(--column-large);
        font-weight: 400;
        @include size-under(desktop_l) {
            max-width: calc(var(--column-large) * 1.5);
        }
        @include size-under(desktop) {
            font-size: 60px;
            letter-spacing: -1.2px;
            max-width: calc(var(--column-large) * 1.5);
        }
        @include size-under(tablet_l) {
            max-width: calc(var(--column-large) * 2);
            font-size: 36px;
            letter-spacing: -1.2px;
        }
        @include size-under(tablet) {
            font-size: 28px;
            letter-spacing: -1.2px;
            top: 65%;
            max-width: 100%;
            padding-right: var(--column-small);
            padding-left: var(--column-small);
            position: static;
        }
        @include size-under(phone) {
            font-size: 28px;
            letter-spacing: -1px;
        }
    }

    canvas {
        pointer-events: all;
        height: 500px;
        width: 100%;
        z-index: 123;
    }

}

.body-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 100vh;
    bottom: 0;
    background: linear-gradient(187deg, rgba(25, 25, 25, 1) 8.07%, rgba(72, 191, 227, 1) 36.49%, rgba(89, 120, 211, 1) 71%, rgba(105, 48, 195, 1) 105.51%);

    &:before {
        content: '';
        background: linear-gradient(180deg, rgba(25, 25, 25, 1) 0%, rgba(25, 25, 25, 0.00) 100%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 400px;
    }
}