@import "@/styles/main.scss";


.wrapper {
    padding: 200px 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    background-color: #ffffff;
    @include size-under(tablet) {
        padding: 100px 0px;
    }
}

.contact-content {
    position: relative;
    width: calc(100% - 2 * 7.14%);
    padding-left: var(--column-small);
    padding-right: var(--column-small);
    margin-bottom: 100px;
    h2 {
        color: #191919;
        @include h2;
        line-height: 1.1;
        margin-bottom: 40px;
        @include size-under(tablet_l) {
            margin-bottom: 20px;
        }
    }
    p {
        color: #191919;
        font-weight: 350;
        @include size-under(tablet_l) {
            padding-right: 20px;
        }
    }
    @include size-under(desktop) {
        width: 100%;
    }
}

.bg-logo {
    position: absolute;
    left: 90%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.05;
    z-index: 0;
    svg {
        width: 1000px;
        height: 1000px;
        color: #191919;
    }
}