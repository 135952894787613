@import "@/styles/main.scss";

.wrapper {
    background-color: #191919;
    padding: 200px 0px 250px 0px;
    z-index: 10;
    padding-left: var(--column-small);
    color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 40px;
    @include size-under(tablet_l) {
        padding-top: 75px;
        padding-bottom: 75px;
        padding-right: var(--column-small);
        gap: 0px;
    }
}

.split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    @include size-under(tablet_l) {
        flex-direction: column;
        align-items: flex-start;
    }

}

.services {
    display: flex;
    flex-direction: column;
    h2 {
        color: var(--aqua);
    }

    h4 {
        font-size: 52px;
        line-height: 74px;
        letter-spacing: -3.12px;
        font-weight: 500;
        color: var(--aqua);
        margin-bottom: 20px;
        @include size-under(desktop) {
            font-size: 30px;
        }
    }
}

.service-items {
    @include size-under(tablet_l) {
        display: flex;
        flex-direction: column;
    }
}

.icons {
    width: 100%;
    height: 300px;
    position: relative;
    margin-right: var(--column-small);
    @include size-over(phone) {
        height: 375px;
    }
    @include size-over(desktop) {
        width: 30%;
    }
    @include size-over(tablet_l) {
        width: 50%;
        height: 400px;
    }
}

.service {
    font-size: 90px;
    line-height: 1;
    margin-bottom: 40px;
    letter-spacing: -5.4px;
    font-weight: 500;
    opacity: 0.5;
    font-family: var(--font-display);
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    @include size-under(site_max) {
        font-size: 80px;
    }
    @include size-under(desktop) {
        font-size: 70px;
    }
    @include size-under(tablet_l) {
        font-size: 50px;
        letter-spacing: -2.2px;
        margin-bottom: 30px;
    }
    @include size-under(tablet) {
        font-size: 42px;
        letter-spacing: -2.2px;
    }
    @include size-under(phone) {
        font-size: 32px;
        letter-spacing: -2.2px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    &.active {
        opacity: 1;
        color: var(--aqua);
    }
}