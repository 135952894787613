@import "@/styles/main.scss";
.wrapper {
  pointer-events: none;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  display: block;

  &.loaded {
    opacity: 1;
  }

  canvas {
    pointer-events: all;
    height: 500px;
    width: 100%;
  }

  @include size-under(phone) {
    display: none;
  }
}

.mobile-image {
  display: none;

  @include size-under(phone) {
    display: block;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.image-wrapper {
  height: 400px; 
  padding: 20px; 
  display: flex; 
  flex-direction: column;
  justify-content: center;
  display: none;
  animation: mess 5s linear 0s infinite forwards alternate;

  @include size-under(phone) {
    display: flex;
  }
}

@keyframes mess {
  0%{transform: scale(1) translateX(0) translateY(0) rotateX(0deg) rotateY(0deg)}
  100%{transform: scale(0.98) translateX(5px) translateY(5px) rotateX(15deg) rotateY(5deg)}
}