@import "@/styles/main.scss";
.icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    top: 0;
    bottom: 0;
    width: 550px;
    height: 550px;
    @include size-under(desktop_l) {
        width: 450px;
    }
    @include size-under(desktop) {
        width: 400px;
    }
    @include size-under(tablet_l) {
        width: 350px;
    }
    @include size-under(tablet) {
        width: 250px;
    }
    &.feature {
        opacity: 0.05;
        left: 100%;
        transform: translateX(-50%) scale(3);
        @include size-under(tablet) {
            left: 50%;
            transform: scale(2);
        }
        &.small {
            transform: translateX(-50%) scale(1.5);
            left: 80%;
        }
    }
}